<template>
  <div v-for="row in rows" :key="row" class="list-group mb-2">
    <div class="list-group-item" style="height: 64px"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LiquidityPoolSummarySkeleton",
  components: {},
  setup() {
    return {
      rows: parseInt(process.env.VUE_APP_API_LIMIT),
    };
  },
});
</script>
