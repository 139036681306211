<template>
  <liquidity-pool-summary-skeleton v-if="loading" />
  <error v-else-if="error" />
  <template v-for="(liquidityPool, idx) in liquidityPools" v-else :key="idx">
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="flex-shrink-0 align-self-center d-none d-xxl-inline-flex me-1"
          >
            <asset-details-link
              v-tooltip
              class="flex-shrink-0 d-flex mx-1"
              style="height: 36px; width: 36px"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('liquidity_pools_summary.asset_icon')"
              :sep11="liquidityPool.reserves[0].asset"
            >
              <img
                :src="
                  liquidityPool.reserves[0].asset === 'native'
                    ? xlmLogo
                    : assetMeta(liquidityPool.reserves[0].asset)?.image
                "
                class="px-1 bg-body-tertiary p-2 rounded align-self-center mx-auto img-fluid"
              />
            </asset-details-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div
                class="col-xxl-3 align-items-center d-flex"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('liquidity_pools_summary.reserve')"
              >
                <div class="flex-grow-1 ms-1">
                  <div
                    class="fw-bold text-body font-monospace small d-flex align-items-center justify-content-between"
                  >
                    <asset-details-link
                      v-tooltip
                      :sep11="liquidityPool.reserves[0].asset"
                      class="text-decoration-none text-body"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.asset_name')
                      "
                      >{{
                        liquidityPool.reserves[0].asset === "native"
                          ? $t("liquidity_pools_summary.xlm")
                          : liquidityPool.reserves[0].asset.split(":")[0]
                      }}</asset-details-link
                    >
                    <span
                      v-tooltip
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.amount')
                      "
                      >{{ liquidityPool.reserves[0].amount }}</span
                    >
                  </div>
                  <div
                    class="text-body font-monospace small d-flex align-items-center justify-content-between"
                  >
                    <asset-details-link
                      v-if="liquidityPool.reserves[0].asset === 'native'"
                      v-tooltip
                      :sep11="liquidityPool.reserves[0].asset"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t(
                          'liquidity_pools_summary.native_asset_of_stellar_network',
                        )
                      "
                    >
                      {{
                        $t("liquidity_pools_summary.native")
                      }}</asset-details-link
                    >
                    <asset-details-link
                      v-else
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: liquidityPool.reserves[0].asset.split(':')[1],
                      }"
                      :sep11="liquidityPool.reserves[0].asset"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.asset_issuer')
                      "
                    >
                    </asset-details-link>
                    <span
                      v-tooltip
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.total_shares')
                      "
                      >{{ liquidityPool.total_shares }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                v-tooltip
                class="col-xxl-3 align-items-center d-flex bg-body-tertiary rounded"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('liquidity_pools_summary.reserve')"
              >
                <asset-details-link
                  v-tooltip
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="
                    $t('liquidity_pools_summary.asset_icon')
                  "
                  :sep11="liquidityPool.reserves[1].asset"
                >
                  <img
                    :src="
                      liquidityPool.reserves[1].asset === 'native'
                        ? xlmLogo
                        : assetMeta(liquidityPool.reserves[1].asset)?.image
                    "
                    class="bg-body-secondary rounded p-1 align-self-center mx-auto img-fluid"
                  />
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="fw-bold text-body font-monospace small d-flex align-items-center justify-content-between"
                  >
                    <asset-details-link
                      v-tooltip
                      :sep11="liquidityPool.reserves[1].asset"
                      class="text-decoration-none text-body"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.asset_name')
                      "
                    >
                      {{
                        liquidityPool.reserves[1].asset === "native"
                          ? $t("liquidity_pools_summary.xlm")
                          : liquidityPool.reserves[1].asset.split(":")[0]
                      }}</asset-details-link
                    >
                    <span
                      v-tooltip
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.amount')
                      "
                      >{{ liquidityPool.reserves[1].amount }}</span
                    >
                  </div>
                  <div
                    class="text-body font-monospace small d-flex align-items-center justify-content-between"
                  >
                    <asset-details-link
                      v-if="liquidityPool.reserves[1].asset === 'native'"
                      v-tooltip
                      :sep11="liquidityPool.reserves[1].asset"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t(
                          'liquidity_pools_summary.native_asset_of_stellar_network',
                        )
                      "
                    >
                      {{ $t("liquidity_pools_summary.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: liquidityPool.reserves[1].asset.split(':')[1],
                      }"
                      :sep11="liquidityPool.reserves[1].asset"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.asset_issuer')
                      "
                    ></asset-details-link>
                    <span
                      v-tooltip
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.total_trustlines')
                      "
                      >{{ liquidityPool.total_trustlines }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-xxl-2 d-flex align-items-center">
                <div class="flex-grow-1 ms-2">
                  <div
                    class="fw-bold text-body font-monospace small d-flex align-items-center justify-content-between me-2"
                  >
                    <span class="text-decoration-none text-body">
                      {{ $t("liquidity_pools_summary.price") }}</span
                    >
                    <span
                      v-tooltip
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        assetMeta(liquidityPool.reserves[0].asset)?.price_usd
                          ? $t('liquidity_pools_summary.reserve') +
                            ' 1: $ ' +
                            parseFloat(
                              assetMeta(liquidityPool.reserves[0].asset)
                                ?.price_usd,
                            ).toLocaleString()
                          : '-'
                      "
                    >
                      {{
                        assetMeta(liquidityPool.reserves[0].asset)?.price_xlm
                          ? "XLM " +
                            parseFloat(
                              assetMeta(liquidityPool.reserves[0].asset)
                                ?.price_xlm,
                            ).toLocaleString()
                          : "-"
                      }}
                    </span>
                  </div>
                  <div
                    class="text-body font-monospace small d-flex align-items-center justify-content-end me-2"
                  >
                    <span
                      v-tooltip
                      class="small text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        assetMeta(liquidityPool.reserves[1].asset)?.price_usd
                          ? $t('liquidity_pools_summary.reserve') +
                            ' 2: $ ' +
                            parseFloat(
                              assetMeta(liquidityPool.reserves[1].asset)
                                ?.price_usd,
                            ).toLocaleString()
                          : '-'
                      "
                      >{{
                        assetMeta(liquidityPool.reserves[1].asset)?.price_xlm
                          ? "XLM " +
                            parseFloat(
                              assetMeta(liquidityPool.reserves[1].asset)
                                ?.price_xlm,
                            ).toLocaleString()
                          : "-"
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="ms-xxl-auto col-xxl-2 align-items-center d-flex bg-body-tertiary rounded"
              >
                <div class="flex-grow-1 ms-2">
                  <div class="fw-bold text-body font-monospace small d-flex align-items-center justify-content-between me-2">
                    <span class="text-decoration-none text-body">
                      {{ $t("liquidity_pools_summary.base_fee") }}
                    </span>
                    <span
                      v-tooltip
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        assetMeta(liquidityPool.reserves[0].asset)?.price_usd
                          ? $t('liquidity_pools_summary.reserve') +
                            ' 1: $ ' +
                            parseFloat(
                              assetMeta(liquidityPool.reserves[0].asset)
                                ?.price_usd,
                            ).toLocaleString()
                          : '-'
                      ">
                      {{liquidityPool.fee_bp / 100}} 
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="ms-xxl-auto col-xxl-2 align-items-center d-flex bg-body-tertiary rounded"
              >
                <div
                  class="flex-grow-1 text-body text-end small font-monospace"
                >
                  <router-link
                    :to="{
                      name: 'LiquidityPoolDetailsRoute',
                      params: { pool_id: liquidityPool.id },
                    }"
                    class="float-end text-decoration-none"
                    >{{ $t("liquidity_pools_summary.view_pool") }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDate } from "@/utils/date.js";
import LiquidityPoolSummarySkeleton from "@/components/skeletons/summaries/LiquidityPoolSummarySkeleton.vue";
import Error from "@/components/Error.vue";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import { splitAndCapitalize } from "@/utils/string.js";

export default defineComponent({
  name: "LiquidityPoolsSummary",
  components: {
    LiquidityPoolSummarySkeleton,
    Error,
    AssetDetailsLink,
  },
  setup() {
    const store = useStore();
    const liquidityPools = computed(
      () => store.getters["liquidity_pools/getLatestLiquidityPools"],
    );
    const loading = computed(
      () => store.getters["liquidity_pools/getLoadingStatus"],
    );
    const error = computed(() => store.getters["liquidity_pools/getError"]);
    const assetMeta = (asset) => {
      const arr = asset.split(":");
      return assetsMeta.value[arr[0] + "_" + arr[1]];
    };
    const assetsMeta = computed(
      () => store.getters["assets/getAssetsMeta"].data,
    );

    return {
      liquidityPools,
      loading,
      error,
      formatDate,
      assetMeta,
      splitAndCapitalize,
      xlmLogo: require("@/assets/images/assets/xlm.png"),
    };
  },
});
</script>
