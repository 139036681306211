<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
    <div class="ms-2">
      <div style="min-width: 90px" class="d-flex justify-content-between">
        <span
          v-tooltip
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('liquidity_pools_page.previous_page')"
        >
          <button
            class="btn bg-body text-primary border"
            :disabled="disablePrevBtn"
            @click="prevPage()"
          >
            <i class="bi bi-arrow-left"></i></button
        ></span>
        <span
          v-tooltip
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('liquidity_pools_page.next_page')"
        >
          <button
            class="btn bg-body text-primary border"
            :disabled="disableNextBtn"
            @click="nextPage()"
          >
            <i class="bi bi-arrow-right"></i></button
        ></span>
      </div>
    </div>
  </div>
  <liquidity-pools-summary />
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import LiquidityPoolsSummary from "@/components/summaries/LiquidityPoolsSummary.vue";
import Search from "@/components/Search";

export default defineComponent({
  name: "LiquidityPools",
  components: {
    LiquidityPoolsSummary,
    Search,
  },
  setup() {
    const store = useStore();

    store.dispatch("liquidity_pools/fetchLiquidityPools");

    const nextPage = () => {
      store.dispatch("liquidity_pools/fetchNextPage");
    };
    const prevPage = () => {
      store.dispatch("liquidity_pools/fetchPrevPage");
    };
    const disablePrevBtn = computed(
      () => store.getters["liquidity_pools/disablePrevBtn"]
    );
    const disableNextBtn = computed(
      () => store.getters["liquidity_pools/disableNextBtn"]
    );
    return {
      nextPage,
      prevPage,
      disablePrevBtn,
      disableNextBtn,
    };
  },
});
</script>
